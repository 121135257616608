import '../css/z_header.css';

// 显示/隐藏侧边栏
let menuEl = $('.mu8-body .mu8-menu');
let sidebarWidth = menuEl.width();

// 检测当前页面是否地图模式
if (window.location.href.match(/map=1/) !== null) {
    $('.mu8-sidebar-hide-btn-content').html('显示侧边栏');
    menuEl.animate({ 'left': (-sidebarWidth) }, 500);
    $('.mu8-main').animate({ 'margin-left': '0px' }, 500);
}

$('.mu8-sidebar-hide-btn').on('click', function () {
    let sidebarLeft = menuEl.css('left').replace('px', '') * 1;
    if (sidebarLeft === 0) {
        $('.mu8-sidebar-hide-btn-content').html('显示侧边栏');
        menuEl.animate({ 'left': (-sidebarWidth) }, 500);
        $('.mu8-main').animate({ 'margin-left': '0px' }, 500);
    } else {
        $('.mu8-sidebar-hide-btn-content').html('隐藏侧边栏');
        menuEl.animate({ 'left': '0px' }, 500);
        $('.mu8-main').animate({ 'margin-left': sidebarWidth + 10 }, 500);
    }
})